import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";

import BannerSection from "Containers/Banner";
import ServiceSection from "Containers/Service";
import Navbar from "Containers/Navbar";

import "@redq/reuse-modal/es/index.css";
import Seo from "components/seo";
import { graphql, StaticQuery } from "gatsby";
import ProductSlide from "Containers/ProductSlide";
import Testimonial from "Containers/Testimonial";
import FeatureSlider from "Containers/FeatureSlider";
import Footer from "Containers/Footer";
import { proceedPayment, paymePromise } from "../common/components/payment";
import { openModal, closeModal } from "@redq/reuse-modal";
import Button from "common/components/Button";
import CheckCoupon from "../common/components/CheckCoupon";

const SaasClassic = ({ location }) => {
  const [Tour, setTour] = React.useState();
  const [reviews, setReviews] = React.useState();
  const [profileVideo, setProfileVideo] = React.useState(null);
  const [profilePic, setProfilePic] = React.useState(null);
  const [paymeURL, setPaymeURL] = React.useState(null);
  const [source, setSource] = React.useState("Dguide");

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);

    let source_id = params.get("source");
    if (!source_id) {
      source_id = "Dguide";
    }

    setSource(source_id);

    async function getParams() {
      if (Tour && Tour.all_media) {
        let pVideo = Tour.all_media.filter((media) =>
          media.type.includes("video")
        )[0];
        pVideo.url = "https://www.youtube.com/embed/1ZCCYbPQUeA";
        setProfileVideo(pVideo);
        const pPic = Tour.all_media.filter((media) =>
          media.type.includes("image")
        );
        setProfilePic(pPic);
        try {
          await proceedPayment(Tour, setPaymeURL, source, "en");
        } catch (error) {
          console.log("ERROR", error);
        }
      }
    }

    getParams();
  }, [Tour]);

  /*ADD FOR COUPON START*/
  const [couponText, setCouponText] = React.useState(null);
  const setPrice = async (newPrice) => {
    Tour.price = newPrice;
    //let new_url = await proceedPayment(Tour, setPaymeURL, source)
    paymePromise(Tour, source, "en").then((urlRes) => {
      setPaymeURL(urlRes);
      closeModal();
    });
  };

  const ModalCoupon = () => {
    return (
      <div style={{ width: "100%", height: "750px" }}>
        <CheckCoupon
          couponPlaceHolder="יש לך קופון? פה המקום להכניס אותו"
          setPrice={setPrice}
          price={Tour.price}
          setCouponText={setCouponText}
          couponText={couponText}
          t_id={Tour.id}
          source_id={source}
          lang="En"
        />
      </div>
    );
  };

  const handleCouponModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        width: "100%",
        height: "750px",
      },
      component: ModalCoupon,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  /*ADD FOR COUPON END*/

  const ModalPayment = (paymeURL) => {
    return (
      <div
        style={{ width: "100%", height: "750px", backgroundColor: "#ffffff" }}
      >
        <iframe
          title="paymeWin"
          id="paymeWin"
          src={paymeURL.paymeURL}
          style={{
            marginTop: "10px",
            width: "100%",
            height: "100%",
            position: "absolute",
            overflowY: "scroll",
          }}
          allowFullScreen
        />
      </div>
    );
  };

  const CloseModalButton = () => (
    <Button
      className="modalCloseBtn"
      variant="fab"
      onClick={() => closeModal()}
      icon={<i className="flaticon-plus-symbol" />}
    />
  );

  const handlePaymentModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        width: "100%",
        height: "100%",
      },
      component: ModalPayment,
      componentProps: { paymeURL: paymeURL },
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  return (
    <StaticQuery
      query={graphql`
        query grafitiyulTLVenQuery {
          allTour(filter: { id: { eq: "ffv7KWJnSrt9u9zm1MYY" } }) {
            nodes {
              accessible
              all_media {
                name
                order
                poster_url
                profile
                type
                url
                poster_name
              }
              audience
              description
              distance
              duration
              equipment
              hours_range {
                max
                min
              }
              id
              kosher
              location
              main_sentense
              price
              rating {
                total
                votes
              }
              title
              language
              tour_guide {
                email
                full_name
                id
                profile_image
              }
              type
              hostedBy
            }
          }
          allReviews {
            nodes {
              content
              id
              name
              time
              rating
              imgurl
              lang
            }
          }
        }
      `}
      render={(data) => {
        setReviews(data.allReviews.nodes);
        setTour(data.allTour.nodes[0]);

        if (!Tour || !reviews) return null;

        /* const pVideo = Tour.all_media.filter((media) =>
          media.type.includes("video")
        )[0];
        setProfileVideo(pVideo);
        console.log("profileVideo", profileVideo); */
        //proceedPayment();

        return (
          <ThemeProvider theme={saasClassicTheme}>
            <Fragment>
              <Seo
                title={`Dguide | ${Tour.title}`}
                description={Tour.description}
                lang={Tour.language}
                meta={[
                  "סיורים",
                  "סיורים בירושלים",
                  "טיול בירושלים",
                  "טיול",
                  "טיול למשפחה",
                  "מקומות לטייל בשבת",
                  "איפה אפשר לטייל",
                  "המלצות לטיולים",
                  "טיול יום",
                  "סיור יום",
                  "אטרקציות בירושלים",
                  "סיור עצמאי",
                  "מדריך טיולים",
                  "יום כיף",
                  "מה לעשות בירושלים",
                  "סיורים מודרכים בירושלים",
                  `סיורים ${Tour.location}`,
                  Tour.main_sentense,
                  ...Tour.type,
                ]}
                keywords={[
                  "סיור מודרך",
                  "סיור עצמאי",
                  `מה אפשר לעשות ב ${Tour.location}`,
                ]}
              />
              <Modal />
              <ResetCSS />
              <GlobalStyle />

              <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                  {couponText && (
                    <div
                      style={{
                        textAlign: "center",
                        margin: "3px",
                        fontWeight: "550",
                      }}
                    >
                      {couponText}
                    </div>
                  )}
                  <DrawerProvider>
                    <Navbar
                      handlePaymentModal={handlePaymentModal}
                      handleCouponModal={couponText ? null : handleCouponModal}
                      lang={"En"}
                    />
                  </DrawerProvider>
                </Sticky>
                {profileVideo && (
                  <BannerSection
                    guideName={Tour.hostedBy}
                    tourTitle={Tour.title}
                    tourDesc={Tour.description}
                    tourTrail={profileVideo}
                    handlePaymentModal={handlePaymentModal}
                    lang="En"
                  />
                )}
                <ServiceSection
                  locTour={Tour.location}
                  distTour={Tour.distance}
                  priceTour={Tour.price}
                  durTour={Tour.duration}
                  hoursRange={Tour.hours_range}
                  lang="En"
                />

                {profilePic && <ProductSlide allPics={profilePic} lang="En" />}
                <Testimonial allReviews={reviews} lang="En" />
                <FeatureSlider lang="En" />
                <Footer lang="En" />
                {/*<UpdateScreen />
          <FeatureSection />
          <PartnerSection />
          <PricingSection />
          <TestimonialSection />
          <TrialSection />
          <Newsletter />
           */}
              </ContentWrapper>
            </Fragment>
          </ThemeProvider>
        );
      }}
    />
  );
};
export default SaasClassic;
